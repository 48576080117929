<template>
  <div v-if="authUserIsAdmin" class="card">
    <div class="card-body">
      <LoadingMessage v-if="isLoading"></LoadingMessage>

      <ErrorMessage v-if="error" :error="error" class="my-0 mt-3"></ErrorMessage>

      <div v-if="!isLoading && data" class="list-group list-group-flush">
        <div class="list-group-item d-flex fw-bold">
          <div class="flex-grow-1 me-3">Total users</div>
          <div>{{ data.usersCount }}</div>
        </div>
        <div class="list-group-item d-flex small text-muted">
          <div class="flex-grow-1 me-3">Admins</div>
          <div>{{ data.users.admins }}</div>
        </div>
        <div class="list-group-item d-flex small text-muted">
          <div class="flex-grow-1 me-3">Coaches</div>
          <div>{{ data.users.coaches }}</div>
        </div>
        <div class="list-group-item d-flex small text-muted">
          <div class="flex-grow-1 me-3">Athletes</div>
          <div>{{ data.users.athletes }}</div>
        </div>
        <div class="list-group-item d-flex fw-bold">
          <div class="flex-grow-1 me-3">Total sessions</div>
          <div>{{ data.sessionsCount }}</div>
        </div>
        <!-- <div class="list-group-item d-flex small text-muted">
          <div class="flex-grow-1 me-3">Completed sessions</div>
          <div>{{ data.completedSessionsCount }}</div>
        </div> -->
        <div class="list-group-item d-flex small text-muted">
          <div class="flex-grow-1 me-3">Total exercises</div>
          <div>{{ data.exercisesCount }}</div>
        </div>
        <div class="list-group-item d-flex fw-bold">
          <div class="flex-grow-1 me-3">Total comments</div>
          <div>{{ data.commentsCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'AdminPanelSummary',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      this.error = null;
      try {
        const res = await httpGet('/admin/stat');
        this.data = res.data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      data: null,
    };
  },
  mounted() {
    this.fetch();
  },
};
</script>
